import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/SEO";
import SplashTitle from "../components/SplashTitle";
import { getImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import { FormattedDate, FormattedMessage, useIntl } from "gatsby-plugin-react-intl";

export const pageQuery = graphql`
  {
    file(relativePath: { eq: "wind-turbines.jpg" }) {
      ...SplashImage
    }
  }
`;

const posts = [
  {
    title: "AI meets Space Event",
    href: "https://www.youtube.com/watch?v=cyp3_SlOTCM&t=5764s",
    description:
      "Internationally renowned AI and Space data experts gathered to discuss recent developments on the edge of these future technologies. Ubicube CEO Andreas Salentinig gave a keynote speech about the progress of ubicube's efforts to extract economic and demographic indicators from Big Geodata.",
    datetime: "2021-12-16",
    medium: "youtube",
  },
  {
    title: "ubicube @ United Nations World Space Forum 2021",
    href: "https://www.youtube.com/watch?v=5eFagz7RbxA&t=41s",
    description:
      'Andreas Salentinig held a presentation about ubicubes\' surface water monitoring service at the "Examples and successful initiatives" Session',
    datetime: "2021-12-09",
    medium: "youtube",
  },
  {
    title: "Willkommen im Netzwerk ubicube geospatial solutions!",
    href: "https://www.dataintelligence.at/willkommen-im-netzwerk-ubicube-geospatial-solutions/",
    description:
      "ubicube geospatial solutions ist ein österreichisches High-Tech Startup und B2B Anbieter von Erdbeobachtungs-Services, vorrangig für die Immobilien- und Baubranche. Bei ubicube nutzen wir verschiedenste Geodaten (Satellitenbilddaten, Points of interest Daten etc.), analysieren diese mittels Machine Learning Modellen und leiten daraus Informationen ab, die für unsere Kunden von höchster Relevanz sind. ",
    datetime: "2021-12-20",
    medium: "DIO",
  },
  {
    title: "Außerirdische Renditen mit Weltraumtechnolgie",
    href: "https://www.ubicube.eu/2110_034_GEWINN.pdf",
    description: "Es wird wieder nach den Sternen gegriffen! Die Weltraumindustrie erlebt ein neues Hoch.",
    datetime: "2021-09-29",
    medium: "GEWINN",
  },
  {
    title: 'Data Patchwork, Geodata Cubes and the "Starbucks Effect"',
    href: "https://austria-in-space.at/en/portraits/ubicube_portrait_en.php",
    description:
      "Be it for the selection of new locations for beverage vending machines, the planning of offline marketing campaigns or the evaluation of real estate prices: in order to be able to make well-founded decisions, the most up-to-date and detailed maps possible of demographic and economic indicators (such as average age, household income or day/night population) are required. The problem is that maps from the data sources...",
    datetime: "2021-09-15",
    medium: "Austria in Space",
  },
  {
    title: "Wie ein Grazer Startup mit Weltraumtechnologie Datenlücken schließt",
    href: "https://brutkasten.com/ubicube/",
    description:
      'Das Grazer Startup "ubicube" hat eine Technologie für die Analyse, Visualisierung und Webintegration von Big Geodata als Entscheidungshilfe für Standortfragen entwickelt. Zum Einsatz kommt ein satellitengestütztes Monitoring System.',
    datetime: "2021-09-15",
    medium: "Brutkasten",
  },
  {
    title: "space4mobility hackathon",
    href: "https://infothek.bmk.gv.at/space4mobility-hackathon-weltraumanwendungen-ausgezeichnet/",
    description:
      "Weltraumtechnik gehört zum festen Bestandteil ganz alltäglicher Anwendungen: Telekommunikation, Navigation, Flugverkehrsmanagement – nur ein paar Beispiele die ohne Weltraumsatelliten nicht mehr vorstellbar wären. Der Space4mobility Hackathon Wettbewerb für Startups und Studierende aus den Bereichen Geoinformatik...",
    datetime: "2020-12-01",
    medium: "BMK Infothek",
  },
  {
    title: "ESA Copernicus Hackathlon",
    href: "https://eodc.eu/eodc-congratulates-the-winners-of-copernicus-hackathon-graz/",
    description: "ubicube wins second overall prize and the EODC special prize at the ESA Copernicus hackathon in Graz",
    datetime: "2020-10-16",
    medium: "EODC",
  },
];

export default function PressPage({ data }) {
  const intl = useIntl();
  console.log(data);

  return (
    <Layout intl={intl} title="Press">
      <Seo title="Press" />
      <SplashTitle
        image={getImage(data.file.childImageSharp)}
        subheadline={intl.formatMessage(
          { id: "press.subheadline", defaultMessage: "<mark>ubicube</mark> in Press and Media" },
          { mark: (text) => <mark>{text}</mark> }
        )}
      >
        {intl.formatMessage({ id: "press.headline", defaultMessage: "Read what others write about us" })}
      </SplashTitle>
      <section className="max-w-screen-2xl mx-auto">
        <div className="pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
          <div className="relative max-w-lg mx-auto divide-y-2 divide-gray-200 lg:max-w-7xl">
            <div>
              <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                {intl.formatMessage({ id: "press.selected-articles", defaultMessage: "Selected Articles from the Press" })}
              </h2>
              <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
                <p className="text-xl text-gray-500">
                  {intl.formatMessage({ id: "press.headline", defaultMessage: "Read what others write about us" })}
                </p>
              </div>
            </div>
            <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-10 lg:gap-y-12">
              {posts.map((post) => (
                <div key={post.title}>
                  <p className="text-sm text-gray-500">
                    <FormattedDate value={post.datetime} />
                    <span className="mx-2">&middot;</span>
                    <FormattedMessage id="press.published-in" defaultMessage="published in" />
                    <span className="uppercase"> {post.medium}</span>
                  </p>
                  <a href={post.href} target="_blank" rel="noreferrer" className="mt-2 block">
                    <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    <p className="mt-3 text-base text-gray-500">{post.description}</p>
                  </a>
                  <div className="mt-3">
                    <a href={post.href} className="text-base font-semibold text-uc-600 hover:text-uc-500" target="_blank" rel="noreferrer">
                      <FormattedMessage id="press.read-full-story" defaultMessage="Read full story" />
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
